import React from "react";

// Externals
import spacetime from "spacetime";

// Components
import CustomChart from "./Chart";

// models
import { ChartConfig, Data, Measure, SerieConfig, SerieIds } from "./models";

const stations = [
    { key: "6504", label: "Bruxelles (Arts-Loi)" },
    { key: "6508", label: "Bruxelles (Sainte-Catherine)" },
    { key: "6516", label: "Bruxelles (Parlement UE)" },
    { key: "10614", label: "Bruxelles (Rue Belliard)" },
    { key: "6528", label: "Berchem-Sainte-Agathe" },
    { key: "100036", label: "Ganshoren (Charles-Quint)" },
    { key: "6551", label: "Neder-Over-Heembeek" },
    { key: "6561", label: "Avant-port (Haren)" },
    { key: "6574", label: "Molenbeek-Saint-Jean" },
    { key: "6615", label: "Ixelles" },
    { key: "6622", label: "Uccle" },
    { key: "100032", label: "Bruxelles (bld du Régent)" },
    { key: "7186", label: "Forest" },
];

const debounce = (callback: any, wait: number) => {
    let timeoutId: number | null = null;
    return (...args: any) => {
        if (timeoutId) window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    };
};

const getDayMeasures = (values: Measure[]): Measure[] => {
    return values.map((v) => {
        const date = spacetime(v.timestamp!);
        return {
            date: date,
            timestamp: v.timestamp,
            tick: date.startOf("day").epoch,
            value: v.value,
        } as Measure;
    });
};

const getMonthMeasures = (values: Measure[]): Measure[] => {
    const baseValues = values.map((v) => {
        const date = spacetime(v.timestamp!);
        return {
            date: date,
            timestamp: v.timestamp,
            tick: date.startOf("day").epoch,
            value: v.value,
        } as Measure;
    });

    const aggregatedByMonth = baseValues.reduce(function (
        obj,
        measure: Measure
    ) {
        // Get the integer value of the number
        const month = measure.tick;

        // If the integer doesn't already exist as a key in the object, create it
        if (!obj.hasOwnProperty(month)) {
            obj[month] = [] as Measure[];
        }

        // Push the number to its integer key
        obj[month].push(measure);

        // Pass the object on to the next loop
        return obj;
    },
    {} as { [key: number]: Measure[] });

    const computedAverages = Object.keys(aggregatedByMonth).map(
        (key: string) => {
            const prop = parseInt(key);
            const group = aggregatedByMonth[prop];
            return {
                value:
                    group.reduce((prev, m) => prev + m.value, 0) / group.length,
                date: group[0].date,
                tick: group[0].date.startOf("month").epoch,
                timestamp: group[0].timestamp,
            } as Measure;
        }
    );

    return computedAverages;
};

interface AppProps {
    config: ChartConfig;
}

const App = (props: AppProps) => {
    const [data, setData] = React.useState<Data>({});
    const [averages, setAverage] = React.useState<Measure[]>([]);

    const containerRef = React.useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = React.useState(100);

    const { periodType, periodEnd } = props.config;
    const periodCount = periodType === "day" ? 7 : 12;

    const end = periodEnd ? spacetime(periodEnd).endOf("day") : spacetime.now();
    const endUnix = end.unixFmt("yyyy-MM-ddThh:mm:ss");

    const period =
        periodType === "day" ? `P${periodCount}D` : `P${periodCount}M`;

    React.useEffect(() => {
        (async () => {
            const dataList: Measure[] = [];

            const dataSources = props.config.series.reduce((prev, serie) => {
                if (serie.serie) {
                    return [...prev, serie.serie];
                } else {
                    return [...prev, ...SerieIds];
                }
            }, [] as string[]);

            const uniqueDataSource = dataSources.filter((v, i, a) => a.indexOf(v) === i);

            for (var index in uniqueDataSource) {
                const stationId = uniqueDataSource[index];
                try {
                    const response = await fetch(
                        `https://geo.irceline.be/sos/api/v1/timeseries/${stationId}/getData?timespan=${period}/${endUnix}&interval=byMonth`
                    );

                    const measures = (await response.json()) as {
                        values: Measure[];
                    };
                    if (!measures || !measures.values) return;

                    const stationData =
                        periodType === "day"
                            ? getDayMeasures(measures.values)
                            : getMonthMeasures(measures.values);

                    setData((previousData: Data) => {
                        const aggregate = {
                            ...previousData,
                            [stationId]: stationData,
                        };

                        dataList.push(...aggregate[stationId]);

                        return aggregate;
                    });
                } catch (error) {
                    console.log("errror", error);
                }
            }

            const aggregatedAverages = dataList.reduce(function (
                obj,
                measure: Measure
            ) {
                // Get the integer value of the number

                // If the integer doesn't already exist as a key in the object, create it
                if (!obj.hasOwnProperty(measure.timestamp!)) {
                    obj[measure.timestamp!] = [] as Measure[];
                }

                // Push the number to its integer key
                obj[measure.timestamp!].push(measure);

                // Pass the object on to the next loop
                return obj;
            },
            {} as { [key: number]: Measure[] });

            const computedAverages = Object.keys(aggregatedAverages)
                .map((key: string) => {
                    const prop = parseInt(key);
                    const group = aggregatedAverages[prop];
                    return {
                        value:
                            group.reduce((prev, m) => prev + m.value, 0) /
                            group.length,
                        date: group[0].date,
                        tick: group[0].tick,
                        timestamp: group[0].timestamp,
                        nativeDate: group[0].date.toNativeDate(),
                    } as Measure;
                })
                .sort((a, b) => a.timestamp! - b.timestamp!);

            console.log("computedAverages", computedAverages);

            setAverage(computedAverages);
        })();
    }, []);

    React.useEffect(() => {
        setContainerWidth(containerRef.current?.clientWidth || 100);
        const onResize = debounce(() => {
            setContainerWidth(containerRef.current?.clientWidth || 100);
        }, 100);
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const getWidth = React.useCallback(
        (size: "large" | "half") => {
            const xs = containerWidth < 720;
            const fullSize = containerWidth ? containerWidth + "px" : "100%";
            const halfSize = xs ? fullSize : (containerWidth - 50) / 2.0 + "px";
            if (size === "large") {
                return fullSize;
            } else {
                return halfSize;
            }
        },
        [containerWidth]
    );

    const getLabel = React.useCallback(
        (chartConfig: ChartConfig, serieConfig: SerieConfig) => {
            const periodTypeLabel =
                chartConfig.periodType === "day" ? "jours" : "mois";

            const serieLabel = serieConfig.serie
                ? stations.find((s) => s.key === serieConfig.serie)?.label
                : "Bruxelles";

            return `Mesures de NO2 sur les ${periodCount} derniers ${periodTypeLabel} à ${serieLabel}`;
        },
        []
    );

    const chartConfig = props.config;

    const ticks = React.useMemo(() => {
        if (periodType === "day") {
            return Array.apply(null, Array(periodCount)).map((item, index) => {
                return end
                    .startOf("day")
                    .add(-index, periodType)
                    .toNativeDate();
            });
        } else {
            return Array.apply(null, Array(periodCount)).map((item, index) => {
                return end
                    .startOf("month")
                    .add(-index, periodType)
                    .toNativeDate();
            });
        }
    }, []);

    return (
        <div
            ref={containerRef}
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {props.config.series.map((serieConfig, index) => {
                if (serieConfig.serie) {
                    return (
                        <CustomChart
                            key={index}
                            data={data[serieConfig.serie]}
                            width={getWidth(serieConfig.size)}
                            title={getLabel(chartConfig, serieConfig)}
                            hideTitle={serieConfig.hideTitle || false}
                            periodCount={periodCount}
                            periodType={periodType}
                            ticks={ticks}
                        />
                    );
                } else {
                    return (
                        <CustomChart
                            key={index}
                            data={averages}
                            width={getWidth(serieConfig.size)}
                            title={getLabel(chartConfig, serieConfig)}
                            hideTitle={serieConfig.hideTitle || false}
                            periodCount={periodCount}
                            periodType={periodType}
                            ticks={ticks}
                        />
                    );
                }
            })}
        </div>
    );
};

export default App;
