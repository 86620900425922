import React from "react";

// Externals
import { Chart } from "react-google-charts";
import { HashLoader } from "react-spinners";

// Models
import { Measure } from "../models";

import "./index.css";

interface CustomChartProps {
    data: Measure[];
    title: string;
    hideTitle: boolean;
    width?: string;
    periodCount: number;
    periodType: "day" | "month";
    ticks: Date[];
}

interface Averages {
    [key: number]: {
        sum: number;
        count: number;
        avg: number;
    };
}

const CustomChart = ({
    data,
    title,
    hideTitle,
    width,
    periodCount,
    periodType,
    ticks,
}: CustomChartProps) => {
    if (!data || !data.length) {
        return (
            <div
                style={{
                    height: 400,
                    width: width,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                <HashLoader color="#fd5c63" />
            </div>
        );
    }

    const averageByDay = data.reduce((prev: Averages, current) => {
        const { tick, value } = current;
        const averages = { ...prev };
        if (averages[tick] !== undefined) {
            averages[tick].sum += value;
            averages[tick].count += 1;
            averages[tick].avg = averages[tick].sum / averages[tick].count;
        } else {
            averages[tick] = {
                sum: value,
                count: 1,
                avg: value,
            };
        }
        return averages;
    }, {} as Averages);

    console.log("averages", averageByDay);

    const values = [
        [
            "Date",
            "Mesure de NO2 en µg/m³",
            `Moyenne du ${periodType === "day" ? "jour" : "mois"}`,
            { role: "style", type: "string" },
            "Recommandation de l'OMS",
        ],
        ...data.map((measure) => {
            return [
                measure.date.toNativeDate(),
                measure.value,
                averageByDay[measure.tick].avg,
                "fill-color: transparent",
                10,
            ];
        }),
    ];

    return (
        <div className="google-chart" style={{ width: width }}>
            <Chart
                chartType="ComboChart"
                width={"100%!important"}
                chartLanguage="fr"
                height="400px"
                data={values}
                options={{
                    title: hideTitle ? undefined : title,
                    legend: {
                        position: "none",
                        maxLines: 5,
                    },
                    vAxis: {
                        title: "NO2 (µg/m³)",
                        ticks: [0, 20, 40, 60, 80, 100],
                    },
                    hAxis: {
                        title: "Date",
                        format: periodType === "day" ? "dd/MM" : "MM/yyyy",
                        ticks: ticks,
                    },
                    interpolateNulls: true,
                    connectSteps: false,
                    seriesType: "bars",
                    aggregationTarget: "category",
                    series: {
                        0: { type: "line", color: "#ffb54c" },
                        1: {
                            type: "steppedArea",
                            color: "#ff4646",
                            backgroundColor: "transparent",
                            style: "fill-opacity: 0.2;",
                            connectSteps: false,
                        },
                        2: { type: "line", color: "#7abd7e" },
                    },
                }}
            />
            <div className="legend">
                <ul>
                    <li>
                        <i style={{ backgroundColor: "#ffb54c" }} />{" "}
                        {"Mesure de NO2 en µg/m³"}
                    </li>
                    <li>
                        <i style={{ backgroundColor: "#ff4646" }} />
                        {`Moyenne du ${periodType === "day" ? "jour" : "mois"}`}
                    </li>
                    <li>
                        <i style={{ backgroundColor: "#7abd7e" }} />
                        {"Recommandation de l'OMS"}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default React.memo(CustomChart);
