import { Spacetime } from "spacetime";

export interface Measure {
    timestamp?: number;
    value: number;
    tick: number;
    date: Spacetime;
}
export interface Data {
    [ley: string]: Measure[];
}

export type SerieId =
    | "6504"
    | "6508"
    | "6516"
    | "10614"
    | "6528"
    | "100036"
    | "6551"
    | "6561"
    | "6574"
    | "6615"
    | "6622"
    | "100032"
    | "7186";

export const SerieIds = [
    "6504",
    "6508",
    "6516",
    "10614",
    "6528",
    "100036",
    "6551",
    "6561",
    "6574",
    "6615",
    "6622",
    "100032",
    "7186",
];

export interface ChartConfig {
    periodType: "day" | "month";
    periodEnd?: string;
    series: SerieConfig[];
}

export interface SerieConfig {
    serie?: SerieId;
    size: "large" | "half";
    title?: string;
    hideTitle?: boolean;
}
