import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ChartConfig } from "./models";

const graphRoots = document.getElementsByClassName("data-no2-graph");

for (var index in graphRoots) {
    const root = graphRoots[index];
    const data = root?.getAttribute("data-no2-config") || "[]";
    const config = JSON.parse(data) as ChartConfig;

    ReactDOM.render(
        <React.StrictMode>
            <App config={config} />
        </React.StrictMode>,
        root
    );
}
